import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {

  apiKey: "AIzaSyA8Cqam3WM-uKi7TOkAw0vyQ9R6GkG4fa8",

  authDomain: "lily-s-form.firebaseapp.com",

  projectId: "lily-s-form",

  storageBucket: "lily-s-form.appspot.com",

  messagingSenderId: "341809714893",

  appId: "1:341809714893:web:3a429bb7477bfcb9fe3b84",

  measurementId: "G-Y1JXBV87CH"

};



const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
